// Header.js
import React from 'react';
import './Header.css';

export const Header = () => {
  return (
    <header className="header">
      <div className="header__logo">
        <a href="/"><span className='header__nav__red'>Red</span>banking</a>
      </div>
      <nav className="header__nav">
        <ul>
          <li><a href="#about">Sobre</a></li>
          <li><a href="#account">Conta</a></li>
          <li><a href="#card">Cartão</a></li>
          <li><a href="#investments">Investimentos</a></li>
          <li><a href="#faq">FAQ</a></li>
          <li><a href="#contact">Contato</a></li>
        </ul>
      </nav>
      <div className="header__buttons">
        <button className="header__button header__button--primary">Abra sua conta</button>
        <button className="header__button header__button--secondary">Entrar</button>
      </div>
    </header>
  );
}